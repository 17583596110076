export default {
  top: [
    {
      direction: 'top',
      backgroundColor: '#DA6259',
      backgroundImage: '',
      animationStart: 'data-top-center',
      animationStartAt: 'top: 0vh',
      animationEnd: 'data-350',
      isContent: true,
      animationEndStop: 'top: -0vh',
      id: 0,
      animationAfterEnd: '',
      animationAfterEndStop: ''
    },
    {
      direction: 'top',
      backgroundImage: '',
      backgroundColor: '#E65939',
      animationStart: 'data-0',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-1000',
      isContent: true,
      animationEndStop: 'top: 0vh',
      id: 1,
      animationAfterEnd: '',
      animationAfterEndStop: ''
    },
    {
      direction: 'top',
      backgroundImage: '',
      backgroundColor: '#D13D72',
      animationStart: 'data-1500',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-2000',
      isContent: true,
      animationEndStop: 'top: 0vh',
      id: 2,
      animationAfterEnd: '',
      animationAfterEndStop: ''
    },
    {
      direction: 'bottom',
      backgroundColor: '#51BFED',
      backgroundImage: '',
      animationStart: 'data-2001',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-3000',
      isContent: true,
      animationEndStop: 'top: 0vh',
      id: 3,
      animationAfterEnd: '',
      animationAfterEndStop: ''
    },
    // {
    //   direction: 'bottom',
    //   backgroundColor: '#D43D3D',
    //   backgroundImage: '',
    //       animationStart: 'data-4001',
    // animationStartAt: 'top: -100vh',
    // animationEnd: 'data-5000',
    // animationEndStop: 'top: 0vh'
    // },
    {
      direction: 'bottom',
      backgroundImage: '',
      backgroundColor: '#FBA400',
      hasOpacity: true,
      animationStart: 'data-3001',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-4000',
      isContent: true,
      animationEndStop: 'top: 0vh',
      id: 4,
      animationAfterEnd: '',
      animationAfterEndStop: ''
    }

  ],
  bottom: [
    {
      direction: 'bottom',
      backgroundColor: '#D54A4C',
      backgroundImage: '/images/home1.jpg',
      animationStart: 'data-0',
      animationStartAt: 'transform: scale(1.1)',
      animationEnd: '',
      animationEndStop: '',
      animationAfterEnd: 'data-500',
      animationAfterEndStop: 'transform: scale(1)',
      id: 0
    },
    {
      direction: 'top',
      backgroundImage: '/images/home4.png',
      backgroundColor: '#D43D3D',
      animationStart: 'data-0',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-1000',
      animationEndStop: 'top: 0vh; transform: scale(1.1)',
      animationAfterEnd: 'data-1100',
      animationAfterEndStop: 'transform: scale(1)',
      id: 1
    },
    {
      direction: 'bottom',
      backgroundColor: '',
      backgroundImage: '/images/home3.png',
      animationStart: 'data-1500',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-2000',
      animationEndStop: 'top: 0vh; transform: scale(1.1)',
      animationAfterEnd: 'data-2100',
      animationAfterEndStop: 'transform: scale(1)',
      id: 2
    },
    {
      direction: 'top',
      backgroundImage: '/images/home2.png',
      backgroundColor: '',
      hasOpacity: true,
      animationStart: 'data-2001',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-3000',
      animationEndStop: 'top: 0vh; transform: scale(1.1)',
      animationAfterEnd: 'data-3100',
      animationAfterEndStop: 'transform: scale(1)',
      id: 3
    },
    // {
    //   direction: 'bottom',
    //   backgroundColor: '#BF2525',
    //   backgroundImage: '/images/home4.svg',
    // animationStart: 'data-4001',
    // animationStartAt: 'top: 100vh',
    // animationEnd: 'data-5000',
    // animationEndStop: 'top: 0vh'
    // },
    {
      direction: 'top',
      backgroundImage: '/images/home5.png',
      backgroundColor: '',
      animationStart: 'data-3001',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-4000',
      animationEndStop: 'top: 0vh; transform: scale(1.1)',
      animationAfterEnd: 'data-4100',
      animationAfterEndStop: 'transform: scale(1)',
      id: 4
    }
  ]
}
