<template>
  <div class="home">
    <div class="slide-points">
      <span
        v-for="i in 5"
        :key="i"
        class="points"
        :class="{'active-point': currentActive === i}"
        ></span>
    </div>
    <div v-show="!loading" id="animate">
      <section class="column">
        <ul>
          <app-slide
            v-for="(slide, idx) in slides.top"
            :key="'top-' + idx"
            :index="'left-' + (++idx)"
            @current-view="changeActive"
            :direction="slide.direction"
            :backgroundColor="computedStyleValue(slide,'color')"
            :backgroundImage="computedStyleValue(slide,'bg')"
            :animationStart="slide.animationStart"
            :animationEnd="slide.animationEnd"
            :animationStartAt="slide.animationStartAt"
            :animationEndStop="slide.animationEndStop"
            :animationAfterEnd="slide.animationAfterEnd"
            :animationAfterEndStop="slide.animationAfterEndStop"
          >
            <template v-if="slide.isContent">
              <app-home :ref="'app-home_'+idx" :current-active="currentActive" :slide="slide" :uuid="`uuid-${idx}-top`" :slideContent="{...data[slide.id], ...{controls:true}} || {}" :idx="idx"/>
            </template>
          </app-slide>
        </ul>
      </section>
      <app-mouse />
      <section class="column" ref="bottomSlide">
        <ul>
          <app-slide
            v-for="(slide, idx) in slides.bottom"
            :key="'bottom-' + idx"
            :direction="slide.direction"
            :backgroundColor="computedStyleValue(slide,'color')"
            :backgroundImage="computedStyleValue(slide,'bg')"
            :animationStart="slide.animationStart"
            :animationEnd="slide.animationEnd"
            :animationStartAt="slide.animationStartAt"
            :animationEndStop="slide.animationEndStop"
            :animationAfterEnd="slide.animationAfterEnd"
            :animationAfterEndStop="slide.animationAfterEndStop"
          >
            <template v-if="slide.isContent">
              <app-home :slide="slide"
              :uuid="`uuid-${idx}-bottom`"
              :slideContent="{...data[slide.id], ...{controls:true}} || {}"
              :idx="idx"/>
            </template>
          </app-slide>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import Slide from '@/components/Slide/SlideItem'
import slideConfig from '@/utils/home-slide-config'
import slidetxt from '@/utils/home-slide-config.text'
import MouseAnimation from '@/components/Home/MouseAnimation'
import slideConfigMobile from '@/utils/home-slide-config-mobile'
import HomeComponent from '@/components/HomeContent/HomeComponent'
import axios from 'axios'
export default {
  name: 'home',
  metaInfo: {
    title: 'Ucall - O Maior Contact Center de Angola'
  },
  components: {
    'app-slide': Slide,
    'app-home': HomeComponent,
    'app-mouse': MouseAnimation
  },
  data () {
    return {
      slides: {},
      slidesContent: {},
      isOpen: false,
      showDetalhe: false,
      currentActive: 1,
      opened: false,
      data: [],
      loading: false
    }
  },
  created () {
    this.slides = (screen.width < 560) ? slideConfigMobile : slideConfig
    this.slidesContent = slidetxt
    this.get()
  },
  mounted () {
    this.$skrollr.refresh()
    this.$refs.bottomSlide.style.flexGrow = 1
    this.$eventHub.$on('expand', this.showDetails)
  },
  destroyed () {
    this.$eventHub.$off('expand')
  },
  watch: {
    '$i18n.locale': {
      handler: async function (after, before) {
        await this.get(after)
      },
      deep: true
    },
    currentActive (newVal, oldVal) {
      if (this.data.length) {
        const bodyStyle = document.body.style
        const index = newVal - 1
        const mobile = (screen.width < 560)
        const appHome = this.$refs['app-home_' + newVal]
        if (((mobile && this.data[index].outline) || (appHome && appHome[0].showDetalhe && this.data[oldVal - 1].outline)) && (this.slides.top[index].id + 1) === newVal) {
          bodyStyle.setProperty('--brand-color', this.data[index].color)
        } else {
          bodyStyle.setProperty('--brand-color', '#fff')
          bodyStyle.setProperty('--slide-indicator-color', '#fff')
        }
      }
    }
  },
  methods: {
    computedStyleValue (slide, ref) {
      ref === 'bg' ? ref = 'backgroundImage' : ref = 'backgroundColor'
      if (this.data[slide.id] && slide[ref] !== '') {
        if (ref === 'backgroundImage') {
          return this.data[slide.id].image[0]
        }
        return this.data[slide.id].imageColor
      }
      return slide[ref]
    },
    async get () {
      this.loading = true
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/home-page`, {
          params: {
            $orderby: 'created asc',
            $limit: 5
          },
          headers: {
            'X-Resolve-Urls': 'logo,image',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          let data = []
          resp.data.items.forEach((element) => {
            data.push({ ...{ id: element.id }, ...element.data })
          })
          this.data = data
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
        .finally(() => {
          this.loading = false
        })
    },
    showDetails (currentElement) {
      if (!this.opened) {
        const cln = document.querySelector(`.${currentElement}`).parentElement
        cln.style.position = 'fixed'
        cln.style.zIndex = '999'
        cln.style.height = '100vh'
        cln.style.bottom = '0'
        this.opened = true
      } else {
        const cln = document.querySelector(`.${currentElement}`).parentElement
        cln.style.position = 'relative'
        cln.style.zIndex = '0'
        cln.style.height = '100%'
        this.opened = false
      }
    },
    changeActive (idx) {
      this.currentActive = idx
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100vw;
}

.slide-points {
  position: fixed;
  z-index: 999;
  height: 100%;
  display: none;
  flex-direction: column;
  width: 40px;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  span.points {
    background-color: var(--slide-indicator-color);
    opacity: 0.25;
    height: 18px;
    width: 3px;
    display: block;
    border-radius: 12px;
    margin-bottom: 10px;
  }

  .active-point {
    opacity: 1 !important;
    width: 5px !important;
  }
}

#animate {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.column {
  flex: 1;
  position: relative;
  overflow: hidden;

  ul {
    list-style: none;
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    padding: 0;
    li {
      list-style: none;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .home {
    #animate {
      display: flex;
      flex-direction: row;
      height: 100vh;
      width: 100vw;
      position: fixed;
      .column {
        position: relative;
      }
    }
  }

  .slide-points {
    display: flex;
  }
}
</style>
<style>
html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
