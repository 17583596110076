export default {
  top: [
    {
      direction: 'top',
      backgroundColor: '#DA6259',
      backgroundImage: '',
      animationStart: 'data-top-center',
      animationStartAt: 'top: 0vh',
      animationEnd: 'data-350',
      animationEndStop: 'top: -0vh',
      animationAfterEnd: '',
      animationAfterEndStop: '',
      isContent: true,
      id: 0
    },
    {
      direction: 'top',
      backgroundImage: '/images/home4.png',
      backgroundColor: '#D43D3D',
      animationStart: 'data-0',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-1400',
      animationEndStop: 'top: 0vh; transform: scale(1.1); filter: blur(0px)',
      animationAfterEnd: 'data-2500',
      animationAfterEndStop: ' transform: scale(1)',
      isContent: false,
      id: 1
    },
    {
      direction: 'top',
      backgroundImage: '',
      backgroundColor: '#D13D72',
      animationStart: 'data-1500',
      animationStartAt: 'top: 100vh',
      animationEnd: 'data-3000',
      animationEndStop: 'top: 0vh',
      animationAfterEnd: '',
      animationAfterEndStop: '',
      isContent: true,
      id: 2
    },
    {
      direction: 'top',
      backgroundImage: '/images/home2.png',
      backgroundColor: '',
      hasOpacity: true,
      animationStart: 'data-3001',
      animationStartAt: 'top: 100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-4500',
      animationEndStop: 'top: 0vh; transform: scale(1.1); filter: blur(0px)',
      animationAfterEnd: 'data-5500',
      animationAfterEndStop: ' transform: scale(1)',
      isContent: false,
      id: 3
    },
    // {
    //   direction: 'top',
    //   backgroundImage: '',
    //   backgroundColor: '#E65939',
    // animationStart: 'data-6001',
    // animationStartAt: 'top: 100vh',
    // animationEnd: 'data-7500',
    // animationEndStop: 'top: 0vh'
    // },
    {
      direction: 'bottom',
      backgroundColor: '#FBA400',
      backgroundImage: '',
      animationStart: 'data-4501',
      animationStartAt: 'top: 100vh; zIndex: 1',
      animationEnd: 'data-6000',
      animationEndStop: 'top: 0vh; filter: blur(0px)',
      animationAfterEnd: 'data-8000',
      animationAfterEndStop: '',
      isContent: true,
      id: 4
    }
  ],
  bottom: [
    {
      direction: 'bottom',
      backgroundColor: '#D54A4C',
      backgroundImage: '/images/home1.jpg',
      animationStart: 'data-0',
      animationStartAt: 'filter: blur(0px); transform: scale(1.1)',
      animationEnd: '',
      animationEndStop: '',
      animationAfterEnd: 'data-800',
      animationAfterEndStop: ' transform: scale(1)',
      isContent: false,
      id: 0
    },
    {
      direction: 'bottom',
      backgroundColor: '#D43D3D',
      backgroundImage: '',
      animationStart: 'data-0',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-1400',
      animationEndStop: 'top: 0vh',
      animationAfterEnd: '',
      animationAfterEndStop: '',
      isContent: true,
      id: 1
    },
    {
      direction: 'bottom',
      backgroundColor: '',
      backgroundImage: '/images/home3.png',
      animationStart: 'data-1500',
      animationStartAt: 'top: -100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-3000',
      animationEndStop: 'top: 0vh; transform: scale(1.1); filter: blur(0px)',
      animationAfterEnd: 'data-4500',
      animationAfterEndStop: ' transform: scale(1)',
      isContent: false,
      id: 2
    },
    {
      direction: 'bottom',
      backgroundImage: '',
      backgroundColor: '#51BFED',
      hasOpacity: true,
      animationStart: 'data-3001',
      animationStartAt: 'top: -100vh',
      animationEnd: 'data-4500',
      animationEndStop: 'top: 0vh',
      animationAfterEnd: '',
      animationAfterEndStop: '',
      isContent: true,
      id: 3
    },
    {
      direction: 'top',
      backgroundImage: '/images/home5.png',
      backgroundColor: '',
      animationStart: 'data-4501',
      animationStartAt: 'top: -100vh; zIndex: 1; transform: scale(1)',
      animationEnd: 'data-6000',
      animationEndStop: 'top: 0vh; transform: scale(1.1)',
      animationAfterEnd: 'data-6500',
      animationAfterEndStop: ' transform: scale(1)',
      isContent: false,
      id: 4
    }
  ],
  'slideItems': [
    {
      theme: { themeZero: true },
      index: 0,
      height: 100,
      titleOpacityLow: '10'
    },
    {
      theme: { themeOne: true },
      index: 1,
      height: 70,
      titleOpacityLow: '&'
    },
    {
      theme: { themeTwo: true },
      index: 2,
      height: 100,
      titleOpacityLow: 'Y'
    },
    {
      theme: { themeThree: true },
      index: 3,
      height: 100,
      titleOpacityLow: 'S'
    },
    {
      theme: { themeFour: true },
      index: 4,
      height: 100,
      titleOpacityLow: ''
    },
    {
      theme: { themeFive: true },
      index: 5,
      height: 100,
      titleOpacityLow: ''
    }
  ]
}
