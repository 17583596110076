import { render, staticRenderFns } from "./MouseAnimation.vue?vue&type=template&id=644dc140&scoped=true&functional=true"
var script = {}
import style0 from "./MouseAnimation.vue?vue&type=style&index=0&id=644dc140&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "644dc140",
  null
  
)

export default component.exports