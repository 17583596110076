<template>
  <li
    :[animationStart]="animationStartAt"
    :[animationEnd]="animationEndStop"
    :[animationAfterEnd]="animationAfterEndStop"
    :class="'slide-' + index"
  >
    <section
      class="slide"
      :class="`goto-${direction}`"
      :style="{ backgroundColor, backgroundImage: `url(${backgroundImage})` }"
    >
      <slot />
    </section>
  </li>
</template>

<script>
export default {
  name: 'Slide',
  props: {
    content: String,
    direction: {
      required: true,
      type: String,
      validator: function (value) {
        return ['top', 'bottom'].indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: () => '#DA6259'
    },
    animationStart: {
      type: String,
      required: true
    },
    animationStartAt: {
      type: String,
      required: true
    },
    animationEnd: {
      type: String,
      required: true
    },
    animationEndStop: {
      type: String,
      required: true
    },
    animationAfterEnd: {
      type: String,
      required: true
    },
    animationAfterEndStop: {
      type: String,
      required: true
    },
    backgroundImage: String,
    index: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.index.length) {
      if (screen.width < 560) {
        window.addEventListener('touchmove', this.howPointSlideBe)
      } else {
        window.addEventListener('scroll', this.howPointSlideBe)
      }
      this.howPointSlideBe()

      const elementRollback = document.querySelector(`li.slide-left-2`)
      setTimeout(() => {
        if (elementRollback.classList.contains('skrollable-between')) {
          this.$emit('current-view', 1)
        }
      }, 100)
    }
  },
  methods: {
    howPointSlideBe () {
      const element = document.querySelector(`li.slide-${this.index}`)
      let i
      if (element.offsetTop === 0) {
        i = Number(this.index.split('')[this.index.length - 1])
        this.$emit('current-view', i)
      }
      // prevent point when user refresh
      if (element.offsetTop > 0 && element.offsetTop < 200) {
        i = Number(this.index.split('')[this.index.length - 1])
        this.$emit('current-view', i)
      }

      // change to first point
      const elementRollback = document.querySelector(`li.slide-left-2`)
      if (elementRollback.offsetTop > 100) {
        this.$emit('current-view', 1)
      }
    }
  }
}
</script>

<style>
.slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 100%;
}

@media (min-width: 760px) {
  .slide {
    height: 100vh;
    position: relative;
    z-index: 1;
  }
}
</style>
