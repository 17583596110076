<template>
  <div
    class="container"
    :class="{ active: isOpen, 'container--details': showDetalhe, [uuid]: true }"
    :style="computedStyleValue"
  >
    <div class="card" :class="{ show: showDetalhe }">
      <div class="card--header" v-html="slideContent.title"></div>
      <div v-if="slideContent.lead" class="lead" v-html="slideContent.lead"></div>
      <div class="card--body" :class="{ mt50: showDetalhe }">
        <p
          class="experiencia ff-gilroy"
          :class="{ show: showDetalhe, [slide.direction]: true }"
          v-html="slideContent.subtitle"
        ></p>
      </div>
      <transition name="rotate" mode="in-out">
        <template v-if="slideContent.controls">
          <div class="card--control">
            <button
              class="btn--circle prev"
              @click="toggleShow"
            >
              <i class='bx' :class="'bx-chevron-' + (showDetalhe ? 'left': 'right')"></i>
            </button>
          </div>
        </template>
      </transition>
      <img v-if="slide.id === 3 && $route.query.arc" src="@/assets/arc.png" style="width: 12em; position: absolute; right: 0; bottom: -8em;">
    </div>
    <button
      class="btn--more"
      :class="{ active: isOpen }"
      @click="toogleContact"
      :style="{ color: slideContent.color }"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    slide: Object,
    slideContent: Object,
    uuid: String
  },
  data () {
    return {
      isOpen: false,
      showDetalhe: false
    }
  },
  methods: {
    toggleShow () {
      this.showDetalhe = !this.showDetalhe
      const mobile = screen.width < 560
      const bodyStyle = document.body.style
      const condition = this.showDetalhe && this.slideContent.reverseScheme && (this.slide.id % 2 === 0 || mobile)
      bodyStyle.setProperty('--brand-color', condition || (mobile && this.slideContent.outline) ? this.slideContent.color : '#fff')
      bodyStyle.setProperty('--slide-indicator-color', condition ? '#000' : '#fff')
    },
    toogleContact () {
      this.isOpen = !this.isOpen
      this.toggleShow()
      this.$eventHub.$emit('expand', this.uuid)
      if (this.isOpen) {
        this.$store.commit('SET_CHAT_STATE', false)
      } else {
        this.$store.commit('SET_CHAT_STATE', true)
      }
    }
  },
  computed: {
    computedStyleValue () {
      let backgroundImage = this.slide['backgroundImage']
      let backgroundColor = this.slide['backgroundColor']
      let lightColor = '#fff'
      const darkColor = '#000'
      if (this.slideContent) {
        if (backgroundImage !== '') {
          backgroundImage = this.slideContent.image[0]
        }
        if (backgroundColor !== '') {
          backgroundColor = this.slideContent.color
        }
        const show = this.showDetalhe
        const reverse = this.slideContent.reverseScheme && show
        const outline = this.slideContent.outline
        return {
          backgroundImage: backgroundImage,
          backgroundColor: reverse ? lightColor : outline ? lightColor : backgroundColor,
          '--color': reverse ? backgroundColor : outline ? darkColor : lightColor,
          '--color-lead': reverse ? darkColor : outline ? backgroundColor : lightColor,
          '--color-subtitle': reverse ? darkColor : outline ? darkColor : lightColor,
          '--btn-bg-control': lightColor,
          '--btn-color-control': outline ? (show ? backgroundColor : darkColor) : backgroundColor,
          '--btn-border-control': reverse ? backgroundColor : outline ? (show ? backgroundColor : darkColor) : lightColor
        }
      }
      return { backgroundImage, backgroundColor }
    }
  },
  destroyed () {
    this.isOpen = false
    this.showDetalhe = false
  }
}
</script>
<style>
.lead .card--cite {
  color: var(--color-lead);
}
</style>
<style scoped>
.btn--more {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-style: none;
  outline-style: none;
  padding: 0px 8px;
  border-radius: 50px;
  font-size: 3em;
  color: #d13d72;
  font-weight: 900;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 8px rgba(100, 100, 100, 0.4);
  position: absolute;
  right: 15px;
  bottom: 5%;
  transform: rotate(0deg);
  transition: transform 300ms linear 250ms;
}
.container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10%;
  font-size: 100%;
  color: #fff;
  position: relative;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color);
  margin-top: 50px;
  text-transform: uppercase;
  font-size: 16px;
}

.card.show {
  margin-top: -10px;
}
.container--details .card--body {
  margin-top: 20px;
  color: var(--color-subtitle)
}
.card--control {
  margin-top: 10px;
  display: none;
  z-index: 40;
}
.card--control .btn--circle{
  background-color: var(--btn-bg-control);
  color: var(--btn-color-control);
  border: 1px solid var(--btn-border-control);
  height: 1em;
  width: 1em;
  border-radius: 1em;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card--control.bottom {
  align-self: flex-end;
  margin-right: 24%;
}
.experiencia {
  width: 80%;
  opacity: 0;
  height: 0;
  text-align: justify;
  transition: all 0.2s ease-in-out;
  font-size: 120%;
}
.experiencia.show {
  opacity: 1;
  height: auto;
}
.experiencia.bottom,
.experiencia.top {
  padding: 2px 4px;
  text-align: left;
  text-transform: none;
}
@media only screen and (min-width: 768px) {
  .card--control {
    display: block;
  }

  .show {
    margin-top: 14px;
  }

  .card.show {
    margin-top: 90px;
  }
  .btn--more {
    display: none;
  }
  .card--control.bottom {
    align-self: flex-end;
    margin-right: 2%;
  }
  .container--details .card--title {
    font-size: 1.5em;
  }
  .container--details .card--title::first-line {
    font-size: 2.2em;
  }
  .experiencia.bottom,
  .experiencia.top {
    padding: 2px 4px;
  }
  .container--details .card--cite::first-line {
    font-size: 2.2rem;
  }
}

@media (max-width: 420px) {
  .experiencia {
    font-size: 100%;
  }
}
</style>
<style>
html {
  -webkit-text-size-adjust: 100%;
}
.btn--more.active {
  transform: rotate(45deg);
  transition: transform 300ms linear 250ms;
}
.btn--circle {
  background-color: transparent;
  margin: 0;
  cursor: pointer;
  outline-style: none;
}
.card--title {
  font-size: 150%;
  color: #fafafa;
  font-weight: normal;
}
.card--title span {
  font-size: 2em;
  color: #fff;
  font-weight: 900;
  transition: all .26s ease-in;
}
.card--title-normal {
  font-weight: normal;
  font-size: 1.6em;
  text-transform: uppercase;
  /* width: 65%; */
  box-sizing: border-box;
}
.card--title-normal span {
  font-size: 2.5em !important;
  font-weight: 900;
  transition: all .26s ease-in;
}
.card--cite {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: normal;
}
.card--cite span {
  font-size: 2.8rem;
  font-weight: 900;
  transition: all .26s ease-in;
}
.card--title-normal {
  font-size: 158%;
}
@media only screen and (min-width: 768px) {
  .card--title,
  .card--cite {
    width: 60%;
  }
  .container--details .card--title {
    font-size: 1.7em;
  }
  .container--details .card--title span {
    font-size: 2.2em;
  }
  .container--details .card--cite {
    font-size: 2rem;
  }
  .container--details .card--cite span{
    font-size: 3.8rem !important;
  }
  .container--details .card--title-normal {
    font-size: 1.8em;
  }
  .container--details .card--title-normal span {
    font-size: 2.5em !important;
  }

  /** style desktop information min */
  .card--title,
  .card--cite {
    width: 80%;
  }
  .card--title {
    font-size: 2.1rem;
  }
  .card--title span {
    font-size: 2em !important;
  }
  .card--cite {
    font-size: 180%;
  }
  .card--cite span {
    font-size: 2.4em !important;
  }
  .card--title-normal {
    font-size: 2.4em;
  }
  .card--title-normal span {
    font-size: 2.5em !important;
  }
  .container--details .card--subtitle {
    font-size: 1em;
  }
  .container--details .card--subtitle.big {
    font-size: 3.1em;
    width: 85%;
  }
}

@media only screen and (min-width: 1367px ) {
   .card--title span {
    font-size: 2.4em !important;
  }
}

@media only screen and (min-width: 1600px ) {
  .card--title,
  .card--cite {
    width: 60%;
  }
  .container--details .card--title {
    font-size: 2.3em;
  }
  .container--details .card--title span {
    font-size: 2.2em;
  }
  .container--details .card--cite {
    font-size: 2rem;
  }
  .container--details .card--cite span{
    font-size: 4.5rem !important;
  }
  .container--details .card--title-normal {
    font-size: 2.4em;
  }
  .container--details .card--title-normal span {
    font-size: 2.6em !important;
  }

  /** style desktop information min */
  .card--title,
  .card--cite {
    width: 80%;
  }
  .card--title {
    font-size: 2.9rem;
  }
  .card--title span {
    font-size: 2em !important;
  }
  .card--cite {
    font-size: 280%;
  }
  .card--cite span {
    font-size: 2em !important;
  }
  .card--title-normal {
    font-size: 3em;
  }
  .card--title-normal span {
    font-size: 2.5em !important;
  }
  .card--subtitle.big {
    font-size: 6em !important;
    width: 85%;
  }
  .container--details .card--subtitle {
    font-size: 2em;
  }
  .container--details .card--subtitle.big {
    font-size: 4em !important;
    width: 85%;
  }
}

.card--subtitle.big {
  font-size: 320%;
  width: 85%;
  transition: .26s all ease-in-out;
}
@media only screen and (max-width: 411px) {
  .card--subtitle.big {
    font-size: 294%;
  }
  .card--title {
    font-size: 100%;
    color: #fafafa;
    font-weight: 900;
  }
  .card--cite {
    font-size: 140%;
  }
  .card--cite span {
    font-size: 180%;
  }
  .card--title-normal {
    font-size: 130%;
  }
  .card--title-normal span {
    font-size: 2.5em !important;
  }
  .card--subtitle.big {
    font-size: 230%;
  }
}
</style>
