export default {
  top: [
    {
      title: 'home_slide1.title',
      text: 'home_slide1.subtitle',
      controls: true
    },
    {
      title: 'home_content_slide2.experience',
      text: 'home_content_slide2.body_description',
      controls: true
    },
    {
      title: 'home_content_slide3.experience',
      text: 'home_content_slide3.body_description',
      controls: true
    },
    {
      title: 'home_content_slide4.experience',
      text: 'home_content_slide4.body_description',
      controls: true
    },
    {
      title: 'home_content_slide6.experience',
      text: 'home_content_slide6.body_description',
      controls: true
    }
  ]
}
